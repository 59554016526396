<template>
  <div>
    <div class="title q-mb-lg">
      <h1>LV-Planer</h1>
      <p class="paragraph-1 q-mt-md">Your course scheduling tool</p>
    </div>
    <div class="bg" ></div>
    <!-- Select Add LV or Calendar Management-->
    <div class="col-12 row justify-center q-mb-xl">
        <q-btn text-color="blue-4" color="white" :label="$t('lvplaner.add_lv_button')" @click="this.$router.push('/addlv')" />
    </div>
    <div class="col-12 row justify-center">
      <div class="col-12 q-mt-xl">
    <Calendar />
      </div>
    </div>
  </div>
</template>

<script>
import Calendar from '../components/lvplaner/calendar.vue'
export default {
  data() {
    return {

    }
  },
  components: {
      Calendar
  },
  methods: {
  },


}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

button {
  font-family: "Poppins", sans-serif;
}
.bg {
  position: absolute;
  height: 800px;
  width: 800px;
  top: 80px;
  right: 0;
  background-image: url('../assets/lv-planer-kontur.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: -100;
}
.paragraph-1 {
  font-family: Staatliches, sans-serif;
  color: #5bbdf4;
  font-weight: 400;
  text-align: center;
  font-size: 2vw;
  margin-top: -5%;
}
.title {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 8vh;
}
.title h1 {
  font-family: "Staatliches", sans-serif;
  color: #5bbdf4;
  font-size: 13vw;
  font-weight: 400;
  padding: 0%;
  margin: 0%;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 400px;
}

@media screen and (max-width: 321px) {
  .bg {
    top: 10%;
    width: 60%;
    left: 20%;
    height: 40%;
    opacity: 1;
  }
}

@media screen and (min-width: 321px) and (max-width: 375px) {
  .bg {
    top: 6%;
    width: 60%;
    left: 40%;
    height: 40%;
    opacity: 1;
  }
  .paragraph-1 {
    font-size: 1.1rem;
    margin-top: -5%;
  }
    .title h1 {
    font-size: 20vw;
  }
}

@media screen and (min-width: 375px) and (max-width: 425px) {
  .bg {
    top: 8%;
    width: 80%;
    left: 35%;
    height: 40%;
    opacity: 0.7;
  }
   .paragraph-1 {
    font-size: 1.1rem;
    margin-top: -5%;
  }
    .title h1 {
    font-size: 20vw;
  }
}

@media screen and (min-width: 426px) and (max-width: 768px) {
  .bg {
    top: 2%;
    height: 60%;
  }
    .title h1 {
    font-size: 20vw;
  }
  .paragraph-1 {
    font-size: 1.5rem;
    margin-top: -5%;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .bg {
    top: 4%;
    height: 55%;
    left: 20%;
  }
    .title h1 {
    font-size: 20vw;
  }
  .paragraph-1 {
    font-size: 2rem;
    margin-top: -5%;
  }

}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .bg {
    top: 0;
    height: 75%;
  }
  .paragraph-1 {
    font-size: 1.6rem;
    margin-top: -4%;
  }

}

@media screen and (min-width: 1441px) {
  .bg {
    top: 0;
    height: 95%;
  }
}

</style>